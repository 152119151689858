<template>
  <div class="leave">
    <div class="wrapper">
      <div class="title font-md">
        <span>회원 탈퇴</span>
      </div>
      <div class="desc font-sm">입력해주신 내용으로 더욱 발전하는 오마이컴퍼니가 되겠습니다.</div>
      <div class="form">
        <select :id="`${component.name}Reason`" class="font-sm border-focus-point form-control" v-model="state.form.leaveReason" @change="change()" v-if="state.status.reason === 'select'">
          <option value="">탈퇴 사유를 선택해주세요.</option>
          <option :value="r" v-for="(r, idx) in reasons" :key="idx">{{ r }}</option>
        </select>
        <input :id="`${component.name}Reason`" type="text" class="font-sm border-focus-point form-control" placeholder="탈퇴 사유를 입력해주세요." v-model="state.form.leaveReason" v-else/>
        <div class="input-group" v-show="state.form.leaveReason" v-if="$store.state.account.memberSnsType !== 'apple'">
          <template v-if="state.status.code === 'ready'">
            <input type="text" class="font-sm border-focus-point form-control" aria-label="이메일 주소" :value="$store.state.account.email" readonly/>
            <div class="input-group-append">
              <button class="btn btn-secondary font-xs" @click="send()">이메일 인증</button>
            </div>
          </template>
          <template v-else>
            <input type="text" :id="`${component.name}Code`" class="font-sm border-focus-point form-control" placeholder="인증 코드를 입력해주세요." aria-label="인증 코드" maxlength="4" :readonly="state.status.code === 'complete'"
                   v-model="state.form.code"/>
            <div class="input-group-append">
              <button class="btn btn-secondary font-xs" @click="certify()" :disabled="state.status.code === 'complete'">인증하기</button>
            </div>
          </template>
        </div>
      </div>
      <div class="alert font-sm">
        <ul class="tight">
          <li>이용 불편 및 각종 문의사항 : <a href="tel:023882556" tabindex="-1">02-388-2556</a></li>
          <li>프로젝트를 개설하여 진행중이거나 투자(참여)중인 경우 탈퇴하실 수 없습니다.</li>
          <li>탈퇴 후 회원님의 개인정보는 파기됩니다.</li>
          <li>회원님께서 남기신 게시글, 댓글 및 펀딩한 프로젝트의 투자(참여) 목록 등 투자 이력은 삭제되지 않습니다.</li>
        </ul>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="col pr-0">
          <button class="btn btn-point btn-block font-sm" @click="submit()">탈퇴하기</button>
        </div>
        <div class="col pl-0">
          <button class="btn btn-secondary btn-block font-sm" @click="$store.commit('closeModal', { name: component.name })"> 취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalLeave";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      form: {
        leaveReason: "",
        passwd: "",
        code: "",
      },
      status: {
        reason: "select",
        code: "ready"
      }
    });

    const reasons = ["재가입을 위해", "콘텐츠 품질과 서비스 정보 부족", "고객 서비스의 불만", "시스템 장애", "개인정보(통신 및 신용정보 등) 노출 우려", "기타(직접 입력)"];

    const send = () => {
      http.post(`/api/members/${store.state.account.memberSeq}/account/leave/code`).then(() => {
        state.form.code = "";
        state.status.code = "input";
        store.commit("setSwingMessage", "이메일로 인증 코드를 발송하였습니다. 이메일을 확인하신 후 코드를 입력해주세요.");
      }).catch(httpError());
    };

    const certify = () => {
      state.form.code = state.form.code?.trim();

      if (!state.form.code) {
        document.getElementById(`${component.name}Code`).focus();
        return store.commit("setSwingMessage", "인증 코드를 입력해주세요.");
      }

      http.put(`/api/members/${store.state.account.memberSeq}/account/leave/code/${state.form.code}`).then(() => {
        state.status.code = "complete";
        return store.commit("setSwingMessage", "이메일 인증이 완료되었습니다.");
      });
    };

    const change = () => {
      const idxOf = reasons.indexOf(state.form.leaveReason);

      if (reasons.length - 1 === idxOf) {
        state.status.reason = "input";
        state.form.leaveReason = "";

        nextTick(() => {
          document.getElementById(`${component.name}Reason`).focus();
        });
      }
    };

    const submit = () => {
      const args = {
        leaveReason: state.form.leaveReason?.trim()
      };

      if (!args.leaveReason) {
        document.getElementById(`${component.name}Reason`).focus();
        return store.commit("setSwingMessage", `탈퇴 사유를 ${state.status.reason === "select" ? "선택" : "입력"}해주세요.`);
      } else if (store.state.account.memberSnsType !== "apple" && state.status.code !== "complete") {
        return store.commit("setSwingMessage", "이메일 인증을 진행해주세요.");
      }

      store.commit("confirm", {
        message: "회원 탈퇴를 진행하시겠습니까?",
        allow() {
          http.delete(`/api/members/${store.state.account.memberSeq}/account`, args).then(({data}) => {
            store.dispatch("logoutAccount", {
              home: true,
              afterLogout() {
                store.commit("closeModal", {name: component.name});
                store.commit("setSwingMessage", data.message);
              }
            });
          }).catch(httpError());
        }
      });
    };

    return {component, state, reasons, send, certify, change, submit};
  },
});
</script>

<style lang="scss" scoped>
.leave {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      select {
        height: $formHeightLg;
      }

      input {
        height: $formHeightLg;

        &[type=password] {
          margin-top: $px10;
        }
      }

      .input-group {
        margin-top: $px15;

        .input-group-append .btn {
          width: $px85;
        }
      }
    }

    .alert {
      background: $colorBackground;
      margin: $px25 0 0 0;
      border: $px1 solid #eee;
      padding: $px25 $px20;

      ul {
        li {
          padding-left: $px10;
          position: relative;

          &:before {
            content: "- ";
            position: absolute;
            top: 0;
            left: 0;
          }

          &:not(:last-child) {
            margin-bottom: $px5;
          }
        }
      }
    }
  }

  .actions {
    .row {
      > div {
        button {
          padding: $px15;
          height: $px56;
          border-radius: 0;

          span {
            vertical-align: middle;

            &.img {
              width: $px24;
              height: $px24;
              margin-left: $px8;
            }
          }
        }

        &.left {
          padding-right: 0;
        }

        &.right {
          padding-left: 0;
        }
      }
    }
  }
}
</style>